import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { TokensTableColor } from '~/components/TokensTable/TokensTableColor';
import { Heading5 } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <h2 {...{
      "id": "primitive"
    }}>{`Primitive`}</h2>
    <p>{`«Primitive tokens» representerer fargepaletten til Entur. Disse brukes til å bygge de andre tokensettene.`}</p>
    <TokensTableColor tokenKey="primitive" mdxType="TokensTableColor" />
    <h2 {...{
      "id": "semantic"
    }}>{`Semantic`}</h2>
    <p>{`«Semantic tokens» er laget for å definere hva den brukes til. De er farger som skal brukes til bakgrunn, tekst, stroke og shape til grafiske elementer (for eksempel ikon, illustrasjoner). «Base colors» er bygget på disse, forskjellen på semantiske og base-tokens er at base-tokens tilbyr dark-mode.`}</p>
    <TokensTableColor tokenKey="semantic" mdxType="TokensTableColor" />
    <h2 {...{
      "id": "base"
    }}>{`Base`}</h2>
    <p>{`«Base tokens» er basert på «Semantic tokens» men har støtte for dark-mode. Det er disse vi ønsker du tar i bruk.
Les mer om `}<a parentName="p" {...{
        "href": "/tokens/fargetokens/generelt#hvilken-tokens-skal-jeg-bruke"
      }}>{`hvordan ta i bruk base-tokens`}</a>{`.`}</p>
    <TokensTableColor tokenKey="base" mdxType="TokensTableColor" />
    <h2 {...{
      "id": "datavisualisering"
    }}>{`Datavisualisering`}</h2>
    <p>{`Data-tokens er Entur sin egen palett for farger som skal benyttes til visualisering av data. Les mer om `}<a parentName="p" {...{
        "href": "/identitet/verktoykassen/datavisualisering"
      }}>{`datavisualisering`}</a>{`.`}</p>
    <TokensTableColor tokenKey="data" mdxType="TokensTableColor" />
    <h2 {...{
      "id": "transport"
    }}>{`Transport`}</h2>
    <p>{`Transport-tokens er Entur sine farger på transportmidler. Les mer om `}<a parentName="p" {...{
        "href": "/identitet/verktoykassen/farger#transportfarger"
      }}>{`transportfarger`}</a>{`.
Transparent-variantene brukes i dag for å vise disabled state, disse har svak kontrast og må brukes med omhu.`}</p>
    <TokensTableColor tokenKey="transport" mdxType="TokensTableColor" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      